import { timeTransformer, htmlDecode } from '@shein/common-function'
import { FULL_ORDER_DATE, ORDER_DATE }  from 'public/src/pages/common/biz_helper/timer_format/dictionary'
import dayjs from 'dayjs'
import { queryBatchCheckUrge } from './request'
/**
 * 机器人、订单等 包含订单按钮，状态逻辑集中判断，避免以后改动一个地方，另一个地方遗漏 
 * 订单状态并不等于订单商品状态, 订单状态并不等于订单商品状态, 订单状态并不等于订单商品状态
 * 
 * 订单状态orderStatus:
 * "0": "Waiting for Payment",
 * "1": "Paid",
 * "2": "Expired",
 * "3": "Canceled",
 * "4": "Processing",
 * "5": "Delivered",
 * "6": "Verified",
 * "7": "Delivered",
 * "8": "Revoked",
 * "9": "Rejected",
 * "10": "Shipped",
 * "11": "Refunded",
 * "12": "Waiting for Payment",
 * "13": "Awaiting Verification",
 * "14": "Processing Return",
 * "15": "Processing Return",
 * "16": "Pending",
 * "17": "Need Refund",
 * "18": "Shipped",
 * "19": "Submitted"
 * 
 * 订单商品状态orderGoodsStatus:
 * 
 * 0: 'Waiting for Payment',
 * 1: 'Paid',
 * 2: 'Processing',
 * 3: 'All Shipped',
 * 4: 'Need Refund',
 * 5: 'Refunded',
 * 6: 'Been Exchanged',
 * 7: 'Canceled',
 * 8: 'Expired',
 * 9: 'Out of Stock',
 * 10: 'Been Exchanged',
 * 11: 'Delivered',
 * 12: 'Revoked',
 * 13: 'Rejected',
 * 14: 'Processing Return',
 * 15: 'Arrived At Warehouse',
 * 16: 'Pending',
 * 17: '',
 * 18: 'All Shipped',
 * 19: 'Submitted'
 * 
 * 礼品卡订单状态:
 * 
 * "1": "Waiting for Payment", 等待（未支付）
 * "2": "Canceled", 逾期（已过期）文案叫cancel
 * "3": "Paid", 已支付（待审核）
 * "4": "Processing" 已审核（待发货）
 * "5": "Processing" 正在发货
 * "6": "Sent by email" 已发货 文案叫Sent by email
 * "7": "Delivered" 已签收
 * "8": "Need Refund" 需要退款
 * "9": "Processing Return" 已经退款
 * "10": "Canceled" 订单用户取消
 * "11": "Canceled" 订单客服取消
 * "12": "Pending"  审核中 （该状态待废弃）
 * "13": "Pending" 已支付(滞留中)
 * "14": ""滞留中订单 支付失败 (14状态不会返回，会转成2给前端)
*/
const { lang, SERVER_TYPE, COUNTRY_PAYMENT_METHOD_CONFIG } = gbCommonInfo
const color = {
  0: 'blue',
  1: 'red',
  2: 'green',
  3: 'grey'
}

// 获取商品列表，订单详情取preOrderGoodsList（因为有分包，否则取orderGoodsList）
const getOrderGoodsList = (order = {}) => {
  let orderGoodsList = []
  if (Array.isArray(order.preOrderGoodsList)) {
    orderGoodsList = order.preOrderGoodsList
  } else if (Array.isArray(order.orderGoodsList)) {
    orderGoodsList = order.orderGoodsList
  }
  return orderGoodsList
}

class OrderLogic {
  /**
   * 订单状态
   * 使用到的地方有:
   * 订单列表，订单详情, 个人中心首页, 机器人订单
   */
  orderStatus (language = {}) {
    return {
      0: language.SHEIN_KEY_PC_15032,
      1: language.SHEIN_KEY_PC_15090,
      2: language.SHEIN_KEY_PC_15099,
      3: language.SHEIN_KEY_PC_15028,
      4: language.SHEIN_KEY_PC_15031,
      5: language.SHEIN_KEY_PC_15190,
      6: language.SHEIN_KEY_PC_15280,
      7: language.SHEIN_KEY_PC_15190,
      8: language.SHEIN_KEY_PC_15305,
      9: language.SHEIN_KEY_PC_15307,
      10: language.SHEIN_KEY_PC_15026,
      11: language.SHEIN_KEY_PC_15073,
      12: language.SHEIN_KEY_PC_15032,
      13: language.SHEIN_KEY_PC_15279,
      14: language.SHEIN_KEY_PC_15324,
      15: language.SHEIN_KEY_PC_15324,
      16: language.SHEIN_KEY_PC_15213,
      17: language.SHEIN_KEY_PC_15089,
      18: language.SHEIN_KEY_PC_15026,
      19: language.SHEIN_KEY_PC_14485
    } || {}
  }

  orderStatusColor () {
    return {
      0: color[0],
      1: color[0],
      2: color[3],
      3: color[3],
      4: color[0],
      5: color[2],
      6: color[0],
      7: color[2],
      8: color[3],
      9: color[3],
      10: color[0],
      11: color[2],
      12: color[0],
      13: color[0],
      14: color[0],
      15: color[0],
      16: color[1],
      17: color[0],
      18: color[0],
      19: color[0]
    } || {}
  }

  /**
   * 订单商品状态
   * 使用到的地方有:
   * 订单详情, 取消单件退款
   */
  orderGoodsStatus (language = {}) {
    return {
      0: language.SHEIN_KEY_PC_15025,
      1: language.SHEIN_KEY_PC_15090,
      2: language.SHEIN_KEY_PC_15031,
      3: language.SHEIN_KEY_PC_15027,
      4: language.SHEIN_KEY_PC_15089,
      5: language.SHEIN_KEY_PC_15073,
      6: language.SHEIN_KEY_PC_15070,
      7: language.SHEIN_KEY_PC_15028,
      8: language.SHEIN_KEY_PC_15099,
      9: language.SHEIN_KEY_PC_15071,
      10: language.SHEIN_KEY_PC_15070,
      11: language.SHEIN_KEY_PC_15190,
      12: language.SHEIN_KEY_PC_15305,
      13: language.SHEIN_KEY_PC_15307,
      14: language.SHEIN_KEY_PC_15324,
      15: language.SHEIN_KEY_PC_15325,
      16: language.SHEIN_KEY_PC_15213,
      17: '',
      18: language.SHEIN_KEY_PC_15027,
      19: language.SHEIN_KEY_PC_15963 
    } || {}
  }

  /**
   * 礼品卡订单状态
   * 使用到的地方有:
   * 机器人礼品卡订单
   */
  giftOrderStatus (language = {}) {
    return {
      1: language.SHEIN_KEY_PC_15032,
      2: language.SHEIN_KEY_PC_15028,
      3: language.SHEIN_KEY_PC_15090,
      4: language.SHEIN_KEY_PC_15031,
      5: language.SHEIN_KEY_PC_15031,
      6: language.SHEIN_KEY_PC_17040,
      7: language.SHEIN_KEY_PC_15190,
      8: language.SHEIN_KEY_PC_15089,
      9: language.SHEIN_KEY_PC_15073,
      10: language.SHEIN_KEY_PC_15028,
      11: language.SHEIN_KEY_PC_15028,
      12: language.SHEIN_KEY_PC_15213,
      13: language.SHEIN_KEY_PC_15213,
      14: language.SHEIN_KEY_PC_15028,
    } || {}
  }
  
  giftOrderStatusColor () {
    return {
      1: color[0],
      2: color[3],
      3: color[0],
      4: color[0],
      5: color[0],
      6: color[0],
      7: color[2],
      8: color[0],
      9: color[2],
      10: color[3],
      11: color[3],
      12: color[1],
      13: color[1],
      14: color[3]
    } || {}
  }
  // 物流状态
  fliterStatus (status, language) {
    const statusTxt = {
      签收: language.SHEIN_KEY_PC_16649,
      已发货: language.SHEIN_KEY_PC_15026,
      运输中: language.SHEIN_KEY_PC_16647,
      派送中: language.SHEIN_KEY_PC_16648,
      已下单: language.SHEIN_KEY_PC_16658,
      待取件: language.SHEIN_KEY_PC_16659,
      包裹异常: language.SHEIN_KEY_PC_25687,
    }
    return statusTxt[status] || ''
  }

  // 超省卡待支付取消原因
  xtraOrderCancelReason (language = {}) {
    return [
      { index: 6, title: language.SHEIN_KEY_PC_27034 },
      { index: 7, title: language.SHEIN_KEY_PC_27035 },
      { index: 9, title: language.SHEIN_KEY_PC_27036 },
      { index: 11, title: language.SHEIN_KEY_PC_27037 },
      { index: 8, title: language.SHEIN_KEY_PC_27038 },
      { index: 12, title: language.SHEIN_KEY_PC_27039 },
      { index: 75, title: language.SHEIN_KEY_PC_27041 }
    ]
  }

  // 退货状态
  returnState (item = {}, language = {}) {
    const { order_status, shipping_status } = item
    let txt = ''
    switch (order_status) {
      case 0:
        txt = shipping_status == 10 ? language.SHEIN_KEY_PC_16404 : language.SHEIN_KEY_PC_15031
        break
      case 1:
        txt = language.SHEIN_KEY_PC_15325
        break
      case 2:
        txt = language.SHEIN_KEY_PC_15355
        break
      case 3:
        txt = language.SHEIN_KEY_PC_15356
        break
      case 4:
        txt = language.SHEIN_KEY_PC_15149
        break
      case 5:
      case 6:
        txt = language.SHEIN_KEY_PC_16404
        break
    }

    return htmlDecode({ text: txt })
  }
  
  // 退款状态
  getRefundState (item = {}, language = {}) {
    const { status, refund_record_list = [] } = item
    let refundState = ''
    let refundStateTxt = {
      processing: language.SHEIN_KEY_PC_15031,
      refunded: language.SHEIN_KEY_PC_15073,
      cancelled: language.SHEIN_KEY_PC_16404,
    }
    if ([4, 5, 6].indexOf(status) > -1) { // 退款单状态为4-审核未通过、5-取消退款、6-作废
      refundState = 'cancelled'
    } else if ([3].indexOf(status) > -1) { // 3-已退
      refundState = 'refunded'
    } else if ([2].indexOf(status) > -1) {
      const isExistRefundRecordList = refund_record_list.filter(i => i.status == '8')
      if (isExistRefundRecordList.length) {
        refundState = 'refunded'
      } else {
        refundState = 'processing'
      }
    } else { // 为0-已申请未审核、1-待退款、2-退款中
      refundState = 'processing'
    }

    return htmlDecode({ text: refundStateTxt[refundState] })
  }
  
  // 退款金额价格展示逻辑
  showReturnWayOrReturnAmount (item = {}) {
    let { return_order_goods_list = [], isReturnOrder = 0 } = item
    if (isReturnOrder) {
      let exchangedGoods = return_order_goods_list.filter(list => list.exchange_goods_flag == 1)
      return return_order_goods_list.length == exchangedGoods.length
    } else {
      return false
    }       
  }
  
  // 退款金额价格展示
  isShowRefundAmount (item = {}) {
    return item.refund_scene == 'Refund-06'
  }
  
  // 退款方式文案
  refundPathTxt (item = {}, language = {}) {
    const { refund_path, refund_method } = item
    let text = ''
    if (refund_path == 1) {
      text = language.SHEIN_KEY_PC_15434
    } else if (refund_path == 2) {
      text = language.SHEIN_KEY_PC_16602
    } else if (refund_path == 3 && refund_method) {
      text = language.SHEIN_KEY_PC_16527
    }
    return htmlDecode({ text })
  }

  /**
   * 展示礼品卡状态
   */
  giftOrderIsShowStatus (status) {
    return [1, 2, 3, 4, 5, 6, 8, 9, 12, 13].indexOf(status) > -1
  }

  /**
   * 普通订单取消
   */
  orderCancel (order) {
    return [0, 2, 12, 13].indexOf(order.orderStatus) >= 0
  }

  /**
   * cod短信验证过未发货撤销订单
   */
  isCanRevokeByUser (order = {}) {
    const isCod = order.payment_method == 'cod'
    // 订单状态为【4-Processing】【6-Verified】
    if (![4, 6].includes(order.orderStatus) || !isCod) {
      return false
    }
    // isCanRevokeByUser=false-不可退“且任一商品行的“合流标识”=否或没有值，则按钮不展示
    const orderGoodsList = order.orderGoodsList || []
    const isCombined = orderGoodsList.some(item => {
      if (Array.isArray(item.goods_sn_relation_goods_list)) {
        return item.goods_sn_relation_goods_list.some(list => list.combined_flag != 2)
      } else {
        return item.combined_flag != 2
      }
    })

    const isCanPartCancel = orderGoodsList.some(item => {
      if (Array.isArray(item.goods_sn_relation_goods_list)) {
        return item.goods_sn_relation_goods_list.some(list => list.is_cod_order_can_part_cancel == 1)
      }
    })

    if (!order.isCanRevokeByUser && isCombined && !isCanPartCancel) { 
      return false
    }
    return true
    //return order.isCanRevokeByUser
  }

  disabledRevokeByUser (order = {}) {
    // isCanRevokeByUser=false-不可退“且任一商品行的“合流标识”=为是，则中间层返回终端style=置灰
    const orderGoodsList = order.orderGoodsList || []
    return !order.isCanRevokeByUser && orderGoodsList.some(item => {
      if (Array.isArray(item.goods_sn_relation_goods_list)) {
        return item.goods_sn_relation_goods_list.some(list => list.combined_flag == 2)
      } else {
        return item.combined_flag == 2
      }
    })
  }

  /**
   * 已付款未发货取消订单申请退款
   * * marketing_type:
   * 1: 免费试用
   * 3: 礼赠
   * 5: 拼团
   * 6: 红人
   * 7: 生日礼
   */
  isCommonOrderCanRefund (order = {}) {
    const isCod = order.payment_method == 'cod'
    // 订单状态为【4-Processing】【6-Verified】
    if ([1, 7].includes(+order.marketing_type) || isCod) {
      return false
    }
    // isCommonOrderCanRefund=0-不可退“且商品行的“合流标识”=均为否或没有值，则按钮不展示
    const orderGoodsList = getOrderGoodsList(order)
    const isCombined =  orderGoodsList.every(item => {
      if (Array.isArray(item.goods_sn_relation_goods_list)) {
        return item.goods_sn_relation_goods_list.every(list => list.combined_flag != 2)
      } else {
        return item.combined_flag != 2
      }
    })

    const isCanPartCancel = orderGoodsList.some(item => {
      if (Array.isArray(item.goods_sn_relation_goods_list)) {
        return item.goods_sn_relation_goods_list.some(list => list.is_common_order_can_part_refund == 1)
      }
    })
    
    if (!order.isCommonOrderCanRefund && isCombined && !isCanPartCancel) {
      return false
    }
    return true
    //return order.isCommonOrderCanRefund == 1 && order.marketing_type != 7 && order.marketing_type != 1
  }

  disabledCancelOrder (order) {
    const orderGoodsList = getOrderGoodsList(order)
    // isCommonOrderCanRefund=0-不可退“且存在任一商品行的“合流标识”=是，则中间层返回终端style=置灰
    return !order.isCommonOrderCanRefund && orderGoodsList.some(item => {
      if (Array.isArray(item.goods_sn_relation_goods_list)) {
        return item.goods_sn_relation_goods_list.some(list => list.combined_flag == 2)
      } else {
        return item.combined_flag == 2
      }
    })
  }

  /**
   * 订单商品单件取消
   * 订单详情取preOrderGoodsList（因为有分包，否则取orderGoodsList）
   * 订单列表不会返回isCommonOrderCanPartRefund字段
   */
  isCommonOrderCanPartRefund (order = {}) {
    const isCod = order.payment_method == 'cod'
    if ([1, 7].includes(+order.marketing_type) || [1, 6].includes(order.orderStatus) || isCod) {
      return false
    }
    const orderGoodsList = getOrderGoodsList(order)
    // 如果后端回参所有商品行的isCommonOrderCanPartRefund =0-不可退“且所有商品行的“合流标识”=为空或为否，则中间层返回按钮不展示
    if (!order.isCommonOrderCanRefund && orderGoodsList.every(item => !item.isCommonOrderCanPartRefund && item.combined_flag != 2)) {
      return false
    }
    return true
    // return processing.some(item => {
    //   if (item.isCommonOrderCanPartRefund == 1 && order.marketing_type != 1 && order.marketing_type != 7) return true
    // })
  }

  disabledCancelItem(order) {
    const orderGoodsList = getOrderGoodsList(order)
    // isCommonOrderCanRefund=0-不可退“且存在任一商品行的“合流标识”=是，则中间层返回终端style=置灰
    return !order.isCommonOrderCanPartRefund && orderGoodsList.some(item => item.combined_flag == 2)
  }
  /**
   * 统一判断已支付非cod取消按钮是否展示
   */
  isCommonShowCancelButton(data = {}) {
    const { order = {}, type, showRefundNew = true } = data
    const enterType = showRefundNew ? 'cancel_new' : type
    const actionMap = {
      'cancel_order': () => this.isCommonOrderCanRefund(order),
      'cancel_item': () => this.isCommonOrderCanPartRefund(order),
      'cancel_new': () => {
        return this.isCommonOrderCanRefund(order) || this.isCommonOrderCanPartRefund(order)
      }
    }
    const action = actionMap[enterType]
    return action && action()
  }

  /**
   * 统一判断已支付非cod取消按钮是否置灰
   */
  disabledCancelButton(data = {}) {
    const { order = {}, type, showRefundNew = true } = data
    const enterType = showRefundNew ? 'cancel_new' : type
    const actionMap = {
      'cancel_order': () => this.disabledCancelOrder(order),
      'cancel_item': () => this.disabledCancelItem(order),
      'cancel_new': () => {
        return this.disabledCancelOrder(order) && this.disabledCancelItem(order)
      }
    }
    const action = actionMap[enterType]
    return action && action()
  }
  /**
   * 统一判断已支付非cod取消按钮是否开启abt
   */
  isEnableCancelAbt(data = {}) {
    const { abTestConfig = {}, type, showRefundNew = true } = data
    const enterType = showRefundNew ? 'cancel_new' : type
    const actionMap = {
      'cancel_order': () => {
        return abTestConfig['UnshippedCancelOrder'] === 'on'
      },
      'cancel_item': () => {
        return abTestConfig['CancelItem'] === 'on'
      },
      'cancel_new': () => {
        return actionMap.cancel_order() && actionMap.cancel_item()
      }
    }
    const action = actionMap[enterType]
    return action && action()
  }

  /**
   * cod订单商品部分取消
   * 订单详情取preOrderGoodsList
   */
  isCodOrderCanPartRefund (order = {}) {
    const isCod = order.payment_method == 'cod'
    // 订单状态为【4-Processing】【6-Verified】
    if (![4, 6].includes(order.orderStatus) || !isCod) {
      return false
    }
    const orderGoodsList = getOrderGoodsList(order)
    if (!order.isCanRevokeByUser && orderGoodsList.some(item => item.combined_flag != 2)) {
      return false
    }
    return true
    //return isCod === 'cod' && isCanRevokeByUser
  }
  disabledCodOrderPartRefund(order = {}) {
    const orderGoodsList = getOrderGoodsList(order)
    return !order.isCanRevokeByUser && orderGoodsList.some(item => item.combined_flag == 2)
  }
  /**
   * 订单是否可退货 展示逻辑
   * 当是否可退为“xx” returnable 0:否, 1:是
   * 自主退货类型为“xx”时 voluntaryReturnType 0:停用, 1:启用, 2:降级
   * 机器人页面降级也不展示按钮
   */
  isReturnableInfo (order, type) {
    let { marketing_type, returnable, voluntaryReturnType, returnable_Info } = order
    returnable_Info = returnable_Info ?? {}
    if (type == 'robot') {
      voluntaryReturnType = returnable_Info?.voluntaryReturnType == 1
      returnable = returnable_Info?.returnable
    }
    return returnable && voluntaryReturnType && ![1, 8]?.includes(+marketing_type)
  }

  /**
   * 是否有编辑地址按钮
   * 单个国家的站点 订单地址国家不为本国时隐藏编辑按钮所以用到countryList判断
   * abt的逻辑是poskey:Orderdetail,值 CodAddress=off
   * 1，2的区别是
   * 如果是1且不是老订单直接展示编辑地址按钮
   * 如果是2且不是老订单，展示编辑地址按钮，但置灰不可点击鼠标移上去展示: "送貨地址修改僅支持未出貨訂單"
   * 机器人里面只有=1才会展示按钮入口
   */
  editAddressFlag (order = {}, countryList = [], codAbtAddress) {
    const countryId = +order.shipping_country_id
    const orderStatus = +order.orderStatus
    const businessModel = order.business_model
    const orderPackageInfoList = order.order_package_info_list || []
    const isCod = order?.payment_method === 'cod'
    let countryFlag = false
    countryFlag = (countryList.indexOf(countryId) == -1) || (!['in', 'mx'].includes(lang) && [100, 137].includes(countryId))

    let statusList = orderPackageInfoList.filter(list => [12, 13, 23].includes(+list.status))
    let isCanEdit = [0, 4].includes(+businessModel) || (businessModel == '1' && statusList.length == orderPackageInfoList.length)

    if (isCod) {
      let condition = [6, 0, 13].includes(orderStatus) || ([4].includes(orderStatus) && isCanEdit)
      return condition && codAbtAddress ? 1 : 2
    } else {
      let condition = [1, 0, 12].includes(orderStatus) || ([4].includes(orderStatus) && isCanEdit)
      const orderFlag = condition && !isCod
      if (orderFlag && !countryFlag) {
        return 1
      } else if (!orderFlag) {
        return 2
      } else {
        return false
      }
    }
  }
  disabledEditAddress(order = {}) {
    const orderGoodsList = getOrderGoodsList(order)
    // 任一商品行下的“合流标识”=是，则置灰
    return orderGoodsList.some(item => item.combined_flag == 2)  
  }
  /**
   * 是否可以展示查看发票按钮
   * 这里仅使用订单数据来判断，在实际使用时还需要结合ABT来共同判断是否可以展示
   * ABT的逻辑是poskey: Orderdetail,Orderlist 值invoice=on（默认）/off
   * is_have_invoice - 是否有发票：0 否，1 是
   * 在订单详情页中，orderStatus === 6时不展示按钮
   * @param order
   * @param pageType
   */
  isViewInvoices (order, pageType) {
    const { orderStatus, is_have_invoice } = order || {}
    let activeStatusCollection = [1, 3, 4, 5, 7, 10, 11, 14, 15, 16, 17, 18, 19]
    if (pageType === 'order-list') activeStatusCollection.push(6)
    return is_have_invoice === 1 && activeStatusCollection.indexOf(orderStatus) !== -1
  }
  /**
   * 现金支付取消订单挽留文案
   */
  retainPayMethodLang (language = {}, paymentMethod) {
    let retainLang
    switch (paymentMethod) {
      case 'adyen-konbini':
        retainLang = language.SHEIN_KEY_PC_19605
        break
      case 'dlocal-oxxo':
        retainLang = language.SHEIN_KEY_PC_19609
        break
      case 'ebanx-baloto':
        retainLang = language.SHEIN_KEY_PC_19613
        break
      case 'ebanx-oxxo':
        retainLang = language.SHEIN_KEY_PC_19608
        break
      case 'ebanx-boleto':
        retainLang = language.SHEIN_KEY_PC_19611
        break
      case 'routepay-oxxo':
        retainLang = language.SHEIN_KEY_PC_19609
        break
      case 'routepay-konbini':
        retainLang = language.SHEIN_KEY_PC_19605
        break
      // case 'ebanx-spei':
      //   retainLang = language.SHEIN_KEY_PC_16987
      //   break;
      default:
        retainLang = language.SHEIN_KEY_PC_19607
        break
    }
    return retainLang
  }
  /**
   * waiting for update 提示语
   */
  waitingForUpdateTips (language = {}, paymentMethod, isRW) {
    let tips
    switch (paymentMethod) {
      case 'adyen-konbini':
        tips = language.SHEIN_KEY_PC_19612
        break
      case 'dlocal-oxxo':
        tips = language.SHEIN_KEY_PC_19610
        break
      case 'ebanx-oxxo':
        tips = language.SHEIN_KEY_PC_19610
        break
      case 'ebanx-baloto':
        tips = language.SHEIN_KEY_PC_19616
        break
      case 'ebanx-boleto':
        tips = language.SHEIN_KEY_PC_19615
        break
      case 'ebanx-spei':
        tips = language.SHEIN_KEY_PC_19604
        break
      case 'PayPal-paypal':
        tips = isRW ? language.SHEIN_KEY_PC_17479 : language.SHEIN_KEY_PC_17396
        break
      case 'routepay-oxxo':
        tips = language.SHEIN_KEY_PC_19610
        break
      case 'routepay-konbini':
        tips = language.SHEIN_KEY_PC_19612
        break
      default:
        tips = language.SHEIN_KEY_PC_19606
        break
    }
    return tips
  }
  /**
   * 待发货订单判断
   * cod orderstatus = 4 || 6
   * 非cod orderstatus = 1 || 4
   */
  isWillShipOrder (order = {}) {
    let { payment_method, orderStatus } = order || {}
    return payment_method == 'cod' ? [4, 6].includes(+orderStatus) : [1, 4].includes(+orderStatus)
  }
  /**
   * 订单下所有包裹存在面单前置标识
   * 2 都存在   1 部分存在  0 不存在或者非多包裹
   */
  existFrontFlag (order = {}) {
    let { order_package_info_list = [] } = order || {}
    if (!order_package_info_list?.length) return 0
    let existFrontFlag = order_package_info_list.filter(item => item.operationProcess == 1)
    if (!existFrontFlag?.length) return 0
    return existFrontFlag?.length == order_package_info_list?.length ? 2 : 1
  }
  isRelationBillno (billno = '') {
    if (!billno) return false
    return billno.startsWith ? billno.startsWith('U') : billno.indexOf('U') === 0
  }
  // 订单日期调整
  orderDateFormat (date, showFull = true, localData = null) {
    // 兼容 秒 毫秒 正常时间
    if (!date) return date
    date = (typeof date == 'string') ? date.replaceAll('-', '/') : date
    date =  Number(date) ? (`${date}`.length > 10 ? date : date * 1000) : new Date(date).getTime()
    return timeTransformer({
      time: date,
      sDateMap: showFull ? FULL_ORDER_DATE : ORDER_DATE
    }, localData)
  }
  // HH:mm 小时分钟
  orderShortDateFormat (date, localData = null) {
    if (!date) return date
    return timeTransformer({ time: date * 1000, defaultCode: 'G-2' }, localData)
  }
  // HH:mm:ss 小时分钟秒
  orderCompleteShortDateFormat (date, localData = null) {
    if (!date) return date
    return timeTransformer({ time: date * 1000, defaultCode: 'G-1' }, localData)
  }
  // 订单列表取ccc文案提示的条件
  orderListGetCccTextCond (order = {}, abtParams = '') {
    let { orderStatus = '', order_detail_status = '' } = order || {}
    // 订单状态
    let orderStatusCond = [4, 6, 10].includes(+orderStatus)
    // abt是否开启
    let isOpenAbt = abtParams?.includes('BBC=on')
    return isOpenAbt && orderStatusCond && (order_detail_status == '0' || !!order_detail_status)
  }
  // 订单列表: 取消按钮是否置灰
  orderListB2B2CCond (order = {}, abtParams = '') {
    let { orderStatus = '', order_detail_status = '' } = order || {}
    // 订单状态
    let orderStatusCond = [4, 6].includes(+orderStatus)
    // abt是否开启
    let isOpenAbt = abtParams?.includes('BBC=on')
    // 订单明细状态
    let orderDetailStatusCond = [1, 2, 3].includes(+order_detail_status)
    return isOpenAbt && orderStatusCond && orderDetailStatusCond
  }
  // 订单详情是否满足拆分条件
  orderListOrderStatusCond (order = {}, abtParams = '') {
    let { orderGoodsList = [] } = order || {}
    // abt是否开启
    let isOpenAbt = abtParams?.includes('BBC=on')
    // 包裹明细状态
    let tempOrderGoodsList = orderGoodsList?.flat(Infinity)
    let packDetailStatusList = tempOrderGoodsList?.filter(item => item.pack_detail_status == '0' || !!item.pack_detail_status)
    return isOpenAbt && packDetailStatusList?.length
  }
  // todo 订单这边现在已经不用这个函数了  看机器人模块是否还要使用
  orderDetailB2B2CCond (order = {}, abtParams = '') {
    let { orderGoodsList = [] } = order || {}
    // abt是否开启
    let isOpenAbt = abtParams?.includes('BBC=on')
    // 包裹明细状态
    let tempOrderGoodsList = orderGoodsList?.flat(Infinity)
    let packDetailStatusList = tempOrderGoodsList?.filter(item => [1, 2, 3].includes(+item.pack_detail_status))
    return isOpenAbt && packDetailStatusList?.length
  }
  // 订单详情: 取消按钮是否置灰判断
  orderDetailCancelItemCond (order = {}, abtParams = '') {
    let { orderStatus = '', subOrderStatus = [] } = order || {}
    // 订单状态
    let orderStatusCond = [4, 6].includes(+orderStatus)
    // abt是否开启
    let isOpenAbt = abtParams?.includes('BBC=on')
    // 包裹商品获取
    let goodsList = []
    subOrderStatus?.forEach(item => goodsList = [...goodsList, ...item.goodsList])
    let tempOrderGoodsList = goodsList?.flat(Infinity)
    // 可退商品获取
    let isCanRefundGoodsList = tempOrderGoodsList?.filter(item => item.isCodOrderCanPartCancel || item.isCommonOrderCanPartRefund)
    // 存在包裹明细状态商品
    let existB2b2cGoodsList = isCanRefundGoodsList?.filter(item => [1, 2, 3].includes(+item.pack_detail_status))
    let isAllGoodsList = isCanRefundGoodsList?.length ? existB2b2cGoodsList?.length == isCanRefundGoodsList?.length : false
    return orderStatusCond && isOpenAbt && isAllGoodsList
  }
  // 编辑地址按钮是否置灰判断
  orderDetailEditAddressCond (order = {}, abtParams = '') {
    let { orderStatus = '', order_package_info_list = [], orderGoodsList = [] } = order || {}
    // 订单状态 4-Processing或6-Verified
    let orderStatusCond = [4, 6].includes(+orderStatus)
    // abt是否开启
    let isOpenAbt = abtParams?.includes('Shop=on')
    // status（商品状态） 49-等待发货
    let tempOrderGoodsList = orderGoodsList?.flat(Infinity)
    let waitingGoodsList = tempOrderGoodsList?.filter(item => item.status == 49)
    // status（商品状态） 49-等待发货或23-等待出仓或13-备货中
    let combinedGoodsList = tempOrderGoodsList?.filter(item => [13, 23, 49].includes(+item.status))
    // 包裹操作流程
    let operationProcess = order_package_info_list?.map(item => item.operationProcess)
    let matchGoodStatus = false
    if (operationProcess?.length) {
      if (operationProcess.every(item => Number(item) === 2) && waitingGoodsList?.length) {
        matchGoodStatus = true
      } else if(operationProcess.every(item => Number(item) === 3) && combinedGoodsList?.length) {
        matchGoodStatus = true
      }
    }
    return orderStatusCond && isOpenAbt && matchGoodStatus
  }
  unShipCanDelivery (order = {}, abtParams = '', processOrStockPackage = []) {
    let { order_package_info_list = [] } = order || {}
    let referenceNumberList = processOrStockPackage?.map(item => item.goodsList?.[0]?.reference_number)
    let orderPackageInfoList = order_package_info_list?.filter(item => referenceNumberList?.includes(item?.reference_number || item?.packageNo))

    let operationProcess = orderPackageInfoList?.filter(item => [1].includes(+item.operationProcess))
    return !abtParams || (abtParams && !operationProcess?.length)
  }
  // 满足商家订单可修改地址的判断
  shipCanDelivery (order = {}, abtParams = '', processOrStockPackage = []) {
    let { order_package_info_list = [], orderGoodsList = [], exist_merge_suggestion = '' } = order || {}
    let tempOrderGoodsList = orderGoodsList?.flat(Infinity)
    let offAbt = abtParams?.includes('Shop=off')
    let openAbt = abtParams?.includes('Shop=on')

    let referenceNumberList = processOrStockPackage?.map(item => item.goodsList?.[0]?.reference_number)
    let orderPackageInfoList = order_package_info_list?.filter(item => referenceNumberList?.includes(item?.reference_number || item?.packageNo))
    let existProcess = orderPackageInfoList?.filter(item => [2].includes(+item.operationProcess))
    
    // 所有的未发货包裹对应包裹操作流程的值为空
    let noProcess = orderPackageInfoList?.some(item => !item?.operationProcess)
    
    let goodsStatusList = tempOrderGoodsList?.filter(item => item.status == 49)
    let secondStatusList = existProcess?.filter(item => item.status == 49)

    /**
     * 1、ABT实验（poskey：IsCanRefund）值包含Shop=on
     * 2、所有的未发货包裹对应包裹操作流程的值为空
     * 3、合包建议为0
     * 4、订单下不存在等待发货的商品行
     */
    let condFirst = openAbt && noProcess && (!exist_merge_suggestion || exist_merge_suggestion == 0) && !goodsStatusList?.length
    /**
     * 1、ABT实验（poskey：IsCanRefund）值包含Shop=on
     * 2、存在包裹操作流程（operationProcess）的值为"2-直发"
     * 3、直发的包裹下不存在“商品状态status”=49-等待发货的商品行
     */
    let condSecond = openAbt && existProcess?.length && !secondStatusList?.length

    return offAbt || condFirst || condSecond
  }
  hasDiffParamsValue(baseObj, updateObj) {
    let flag = false
    let keysArr = [
      'fname',
      'lname',
      'stateId',
      'cityId',
      'districtId',
      'postcode',
      'tel',
      'street',
      'address1',
      'address2',
      'nationalId',
      'country_id',
      'tax_number',
      'english_name',
      'middle_name',
      'birthday',
      'id_expire_date'
    ]
    baseObj = {
      ...baseObj,
      country_id: baseObj.countryId || '',
      tax_number: baseObj.taxNumber || '',
      middle_name: baseObj.middleName || '',
      english_name: baseObj.englishName || '',
    }
    keysArr.forEach(key => {
      let baseKeyValue = baseObj[key] || ''
      let updateKeyValue = updateObj[key] || ''
      let baseValue = Number(baseKeyValue) != 0 ? baseKeyValue : Number(baseKeyValue)
      let updateValue = Number(updateKeyValue) != 0 ? updateKeyValue : Number(updateKeyValue)
      if (baseValue != updateValue) {
        flag = true
      }
    })
    return flag
  }
  // 将付费会员订单排序到首位
  sortOrderList (goodsList = []) {
    goodsList = goodsList?.flat(Infinity)
    let xtraGoods = goodsList?.filter(item => item.is_xtra_goods)
    let primeGoods = goodsList?.filter(item => item.is_prime_goods)
    let otherGoods = goodsList?.filter(item => !(item.is_prime_goods || item.is_xtra_goods))
    return [...(xtraGoods || []), ...(primeGoods || []), ...(otherGoods || [])]
  }
  getOrderStatusTextByOrderList (orderStatusText = [], options = {}, isTrack = false) {
    if (!orderStatusText?.length) return null
    /**
     * orderStatus: 订单明细状态/订单节点
     * saleMode: 销售模式 0-自营 1-商家 2-all
     * shoppingCountryIds: 发货国家id列表
     * warpStatus: 是否合包
     * combine_type: 合包类型
     * combine_address: 合包地址
     * sales_area: 销售范围
     * toc_perform_party: 履约方
     * stock_mode: 备货模式
     * combined_flag: 是否合包
      * customizationFlag: 定制商品类型
     */
    let { shipping_country_id, business_model, order_or_package_status, sales_area, toc_perform_party, stock_mode, order_package_info_list = [], orderGoodsList = [], _allOrderGoodsList = [] } = options
    // 订单详情页接口的orderGoodsList是多维数组，扁平化后再进行校验
    let allTempOrderGoodsList = _allOrderGoodsList.length ? _allOrderGoodsList?.flat(Infinity) : orderGoodsList?.flat(Infinity)
    let tempOrderGoodsList = orderGoodsList?.flat(Infinity)
    let matchObj = orderStatusText?.find(item => {
      let { orderStatus, saleMode, shoppingCountryIds = [], wrapStatus, combineType, combineAddress, salesArea, tocPerformParty, stockMode, operationProcess, package_combined_flag, combined_flag, customization_flag } = item
      let matchOrderStatus = order_or_package_status == orderStatus
      let matchSaleMode = saleMode == 2 ? true : business_model == saleMode
      let matchCountryId = shoppingCountryIds?.includes(shipping_country_id) || shoppingCountryIds?.includes(+shipping_country_id)
      let matchWrapStatus = this.getMatchWrapStatus(wrapStatus, order_package_info_list)
      let matchCombineType = !combineType?.length  ? true : tempOrderGoodsList.some(item => combineType.includes(item.combine_type))
      let matchCombineAddress = !combineAddress?.length ? true : tempOrderGoodsList.some(item => combineAddress.includes(item.combine_address))
      let matchSalesArea =  (business_model !== 1 || !salesArea?.length) ? true : salesArea.includes(sales_area)
      let matchTocPerformParty = (business_model !== 1 || !tocPerformParty?.length) ? true : tocPerformParty.includes(toc_perform_party)
      let matchStockMode = (business_model !== 1 || !stockMode?.length) ? true : stockMode.includes(stock_mode)
      let matchOperationProcess = !isTrack ? this.getMatchOperationProcess(operationProcess, order_package_info_list) : true
      let matchPackageCombinedFlag = !isTrack ? this.getMatchPackageCombinedFlag(package_combined_flag, order_package_info_list) : true
      // 列表combined_flag字段在goods_sn_relation_goods_list下
      let matchCombinedFlag = (!combined_flag && combined_flag != 0) || allTempOrderGoodsList.some(item => combined_flag == item?.combined_flag || item?.goods_sn_relation_goods_list?.some(list => combined_flag == list?.combined_flag))
      let matchCustomizationFlag = customization_flag ? allTempOrderGoodsList.some(item => item.customization_flag == customization_flag) : true

      return matchOrderStatus && matchSaleMode && matchCountryId && matchWrapStatus && matchCombineType && matchCombineAddress && matchSalesArea && matchTocPerformParty && matchStockMode && matchOperationProcess && matchPackageCombinedFlag && matchCombinedFlag && matchCustomizationFlag

    })
    return matchObj
  }
  /**
   * 校验订单是否符合是否合包条件
   * 1. ccc规则的是否合包检验条件为空，默认符合
   * 2. ccc规则的是否合包检验条件为1，订单的包裹列表中有一个包裹的wrapStatus为1或2，即符合
   * 3. ccc规则的是否合包检验条件为0，订单的包裹列表中有一个包裹的wrapStatus不为1或2，即符合
   * @param {*} cccWrapStatus ccc规则的是否合包检验条件 1-是，0-否
   * @param {*} order_package_info_list 订单的包裹列表
   * @returns 
   */
  getMatchWrapStatus(cccWrapStatus, order_package_info_list) {
    if(cccWrapStatus == null) return true
    if(!order_package_info_list?.length) return false
    // item.wrapStatus为1-待合包或2-合包完成，则视为”是“，否则视为”否“
    if(cccWrapStatus === 1) {
      return order_package_info_list.some(item => {
        const wrapStatus = item?.wrapStatus || item?.wrap_status
        return wrapStatus === 1 || wrapStatus === 2
      })
    } else if(cccWrapStatus === 0) {
      return order_package_info_list.some(item => {
        const wrapStatus = item?.wrapStatus || item?.wrap_status
        return wrapStatus !== 1 && wrapStatus !== 2
      })
    } else {
      return false
    }
  }
  /**
   *  匹配商家发货模式
   */
  getMatchOperationProcess(cccOperationProcess, order_package_info_list) {
    if(!cccOperationProcess?.length) return true
    if(!order_package_info_list?.length) return false

    return order_package_info_list.some(item => cccOperationProcess?.includes(item?.operationProcess))
  }
  /**
   *  匹配包裹合流标识
   */
  getMatchPackageCombinedFlag(cccPackageCombinedFlag, order_package_info_list) {
    if(!cccPackageCombinedFlag?.length) return true
    if(!order_package_info_list?.length) return false

    return order_package_info_list.some(item => cccPackageCombinedFlag?.includes(item?.packageCombinedFlag))
  }
  /**
   * 获取订单状态文案
   * abt: 巴西b2b2c abt
   * orderStatusTextList: 接口返回ccc配置状态
   */
  getOrderStatusTextInfo ({ order = {}, language = {}, abt = '', orderStatusTextList = [] }) {
    const { order_detail_status, orderStatus } = order
    if (this.orderListGetCccTextCond(order, abt)) {
      let matchObj = this.getOrderStatusTextByOrderList(orderStatusTextList, {
        ...order,
        order_or_package_status: order_detail_status
      })
      return matchObj?.statusText || this.orderStatus(language)[orderStatus]
    } else {
      return this.orderStatus(language)[orderStatus]
    }
  }
  /**
   * 商品是否展示quickship商品标签
   * abt: TradeQuickship
   * 商品集合: item
   * 订单信息: order
   */
  showGoodsQuickShipTag (order = {}, item = {}, abt = '') {
    let { is_multi_mall } = order || {}
    let { status, quick_ship, is_prime_goods } = item || {}
    // 非单mall
    if (is_multi_mall == 1) return false
    // abt关闭
    if (abt?.includes('conceal_QuickShipping')) return false
    // 需要过滤掉的商品状态
    let disabledGoodsStatus = [75, 76, 77, 91, 5, 7, 20, 126, 127, 220]
    let isOpenGoodsStatus = !disabledGoodsStatus.includes(+status) && !is_prime_goods

    return isOpenGoodsStatus && (quick_ship == 1 || quick_ship == 2)
  }
  /**
   * 包裹是否展示quickship商品标签
   * abt: TradeQuickship
   * 包裹下的商品: goodsList
   * 订单信息: order
   */
  showPackageQuickShipTag (order = {}, goodsList = [], abt = '') {
    let { is_multi_mall } = order || {}
    // 非单mall
    if (is_multi_mall == 1) return false
    // abt关闭
    if (abt?.includes('conceal_QuickShipping')) return false
    let newGoodsList = goodsList.flat(Infinity)
    // 需要过滤掉的商品状态
    let disabledGoodsStatus = [75, 76, 77, 91, 5, 7, 20, 126, 127, 220]
    let fullCondGoodsList = newGoodsList?.filter(item => !disabledGoodsStatus?.includes(+item.status) && !item.is_prime_goods)
    let quickShipGoods = fullCondGoodsList?.filter(item => item.quick_ship == 1 || item.quick_ship == 2)

    return quickShipGoods?.length && fullCondGoodsList?.length == quickShipGoods?.length
  }
  /**
   * 店铺下或者订单下的商品是否都是quickship商品
   * abt: TradeQuickship
   * 商品: goodsList
   * return: 1增加 2替换
   */
  getQuickShipTransportTimeType (goodsList = []) {
    let newGoodsList = goodsList?.flat(Infinity)
    // 需要过滤掉的商品状态
    let disabledGoodsStatus = [75, 76, 77, 91, 5, 7, 20, 126, 127, 220]
    let fullCondGoodsList = newGoodsList?.filter(item => !disabledGoodsStatus?.includes(+item.status) && !item.is_prime_goods)
    let quickShipGoods = fullCondGoodsList?.filter(item => item.quick_ship == 1)
    let someQuickShipGoods = fullCondGoodsList?.filter(item => item.quick_ship == 2)
    if (quickShipGoods?.length || someQuickShipGoods?.length) {
      return fullCondGoodsList?.length == quickShipGoods?.length ? 2 : 1
    } else {
      return 0
    }
  }
  /**
   * 商品是否是大件商品
   * 商品: goodsList
   * return: 0没有 1全部是 2部分是
   */
  getLargeTransportTimeType (goodsList = []) {
    let newGoodsList = goodsList?.flat(Infinity)
    // 需要过滤掉的商品状态
    if(!newGoodsList?.length) {
      return 0
    }
    let disabledGoodsStatus = [75, 76, 77, 91, 5, 7, 20, 126, 127, 220]
    let fullCondGoodsList = newGoodsList?.filter(item => !disabledGoodsStatus?.includes(+item.status) && !item.is_prime_goods)
    let hasLargeSeaAndLand = fullCondGoodsList?.filter(item => (item.large_sea_and_land == 1 || item.large_sea_and_land == 2))
    // TODO
    // return 1
    if(hasLargeSeaAndLand.length) {
      if(fullCondGoodsList.length === hasLargeSeaAndLand.length) {
        return 1
      } else {
        return 2
      }
    } else {
      return 0
    }
  }
  /**
   * 礼品卡订单修改收卡邮箱&重发卡密
   * order: 礼品卡订单详情
   * abt: 礼品卡修改abt开关
   * 以GC开头老订单 以及订单状态（取消：2、10、11、14；已退款：9；需要退款：8）不展示修改邮箱订单状态(6-已发货、7-已签收)展示重发按钮
   */
  giftShowOpeBtn ({ order, abt }) {
    return !order.card_order_billno?.startsWith('GC') && ([ 2, 8, 9, 10, 11, 14 ].indexOf(order.status) == -1 || [6, 7].includes(order.status)) && (abt.includes('email=on') || abt.includes('resend=on'))
  }
  /**
   * 订单是否可评论
   * order: 
   * isCanComment:3 部分评论/部分可评论--有已评论且有待评论 1 可评论 --有可评论 2 已评论完 --有已评论 0 不可评论 --除以上情况均为不可评论
   */
  isOrderReview ({ order }) {
    return (order.isCanComment == 1 || order.isCanComment == 3) && !(order.remark_user == 'free trial order' || order.marketing_type == 1)
  }
  getStoreAddressExtraData({ order }) {
    const { mall_list = [], orderGoodsList = [], addTime } = order || {}
    const usedMallList = mall_list.slice(0, 1)
    const mallList = usedMallList.map(({ mall_code, transport_type }) => ({
      mallCode: mall_code,
      transportType: transport_type,
    }))
    const goodsTotalWeight = orderGoodsList.flat(Infinity).reduce((prev, next) => {
      const { product, quantity } = next || {}
      return prev + (Number(product?.weight || 0) * Number(quantity || 1))
    }, 0)
    const { transport_time_type, transport_time } = usedMallList?.[0] || {}
    const transportTime = transport_time_type === 0 ?
      addTime + Number(transport_time)
      :
      `${transport_time}`?.includes('-') ? `${transport_time}`?.split('-')?.[1] : transport_time
    const shippingDate = transportTime ? dayjs(Number(transportTime) * 1000).subtract(3, 'day').unix() : ''
    return {
      mallList,
      goodsTotalWeight,
      shippingDate,
    }
  }
  // 是否属于阿波罗配置里的国家及对应支付方式
  isApolloCountryPayment ({ payment, receiver_country }){
    let _result = false
    if (Object.prototype.toString.call(COUNTRY_PAYMENT_METHOD_CONFIG) == '[object Object]') {
      _result = COUNTRY_PAYMENT_METHOD_CONFIG.hasOwnProperty(receiver_country) && COUNTRY_PAYMENT_METHOD_CONFIG[receiver_country].includes(payment)
    }
    console.log(_result)
    return _result
  }
  // 数组对象去重
  uniqueFunc(arr, uniId){
    const res = new Map()
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
  }
  // 订单页 物流页 跳转机器人设置store code
  setToRobotPage({ order = {}, pageName }) {
    const mall_list = order.mall_list || []
    const newMallList = this.uniqueFunc(mall_list, 'store_code')
    let storeCode = []
    let business_model = []
    
    newMallList.forEach(j => {
      storeCode.push(j.store_code)
      business_model.push(j.business_model)
    })

    gbCommonInfo.robotStoreData = { 
      pageName,
      storeCode: storeCode.join(),
      business_model: business_model.join()
    }
  }

  getRefundSpecialReason () {
    let serverTypeMap = {
      'usa': 136,
      'eur': 130,
      'central': 130
    }
    return serverTypeMap[SERVER_TYPE] || ''
  }

  /** 判断订单地址和修改的地址是否有变化 */
  isDiffShippToEdit(baseObj, updateObj) {
    let flag = false
    let keysArr = [
      'fname',
      'lname',
      'stateId',
      'cityId',
      'districtId',
      'postcode',
      'tel',
      'street',
      'address1',
      'address2',
      'nationalId',
      'country_id',
      'tax_number',
      'english_name',
      'middle_name',
      'birthday',
      'id_expire_date',
      'city',
      'district',
    ]
    baseObj = {
      ...baseObj,
      country_id: baseObj.countryId || '',
      tax_number: baseObj.taxNumber || '',
      middle_name: baseObj.middleName || '',
      english_name: baseObj.englishName || '',
    }
    keysArr.forEach(key => {
      let baseKeyValue = baseObj[key] || ''
      let updateKeyValue = updateObj[key] || ''
      let baseValue = Number(baseKeyValue) != 0 ? baseKeyValue : Number(baseKeyValue)
      let updateValue = Number(updateKeyValue) != 0 ? updateKeyValue : Number(updateKeyValue)
      if (baseValue != updateValue) {
        flag = true
      }
    })
    return flag
  }

  /**
   * @description #886538355 校验未发货是否满足修改条件
   * @param {Object} order 需要校验的订单
   * @param {String} isInfoFrontAbt 满足面单前置的判断
   * @param {String} isCanRefundAbt 满足商家订单可修改地址的判断
  */
  checkShipEdit ({ order, isInfoFrontAbt, isCanRefundAbt, processOrStockPackage }) {
    return this.unShipCanDelivery(order, isInfoFrontAbt, processOrStockPackage) && this.shipCanDelivery(order, isCanRefundAbt, processOrStockPackage)
  }
  /**
   * @description #886538355 校验已发货订单是否可修改地址
   * @param {Object} orders 需要批量查询的订单
   * @param {String} isShippedAddressEnable 发货修改abt
   * @param {String} isInfoFrontAbt 满足面单前置的判断
   * @param {String} isCanRefundAbt 满足商家订单可修改地址的判断
   * @param {String} page 来源 机器人 or 订单详情
   * @returns {Array} 
   * @param {Array} urgeResList 批量返回是否支持
  */
  async checkEditShippingOrder ({ orders = [], isInfoFrontAbt, isShippedAddressEnable, isCanRefundAbt, page = '' }) {
    if (orders?.length && isShippedAddressEnable) {
      let check_urge_request_list = []
      let check_urge_request_list_2 = []
      let type
      let urgeResList
      orders.forEach(order => {
        let { orderStatus, subOrderStatus = [], billno, orderGoodsList = [] } = order || {}
        // 机器人字段值不一致
        let newList = page == 'robot' ? orderGoodsList : subOrderStatus
        let pack = page == 'robot' ? 'package_state' : 'packageState'
        // 是否满足订单状态
        let orderStatusCond = [10].includes(+orderStatus)
        // 均不为processing || out_of_stock状态
        let processOrStockPackage = newList?.filter(item => ['processing', 'out_of_stock'].includes(item[pack]))
        let shipPackage = newList?.filter(item => ['shipped'].includes(item[pack]))
        
        if (orderStatusCond && !processOrStockPackage?.length) {
          // 判断是否都是发货中的商品状态
          let tempOrderGoodsList = orderGoodsList?.flat(Infinity)
          let statusList = tempOrderGoodsList?.filter(item => item.status != '52')
          if (statusList?.length) {
            check_urge_request_list.push({
              billno,
              check_type: 1
            })
          }
          type = 1
        } else if (processOrStockPackage?.length) {
          type = 2
          if (shipPackage?.length) {
            check_urge_request_list.push({
              billno,
              check_type: 1
            })
          }
          if (this.checkShipEdit({ order, isInfoFrontAbt, isCanRefundAbt, processOrStockPackage })) {
            check_urge_request_list_2.push({
              billno,
              display_flag: 1
            })
          }
        }
      })
      if (check_urge_request_list.length) {
        urgeResList = await queryBatchCheckUrge({ check_urge_request_list })
      }
      urgeResList = check_urge_request_list_2.concat(urgeResList?.info?.check_urge_response_list || [])
      return {
        urgeResList,
        type
      }
    }
    return {
      urgeResList: [],
      type: 0
    }
  }
  // COD审单流程新增用户自主审单
  // wiki.pageId=1162457657
  /**
   * @param {Object} order 需要校验的订单
   * @param {Object} language 文案多语言
   * @param {Boolean} codRiskOrderAbt 满足COD审单流程新增用户自主审单Abt
  */
  checkCodRiskOrder ({ order = {}, language = {}, codRiskOrderAbt }) {
    let { is_cod_risk, cod_risk_type } = order || {}
    let textMap = {
      'repeat_before_not_all_ship': language.SHEIN_KEY_PC_26744 || '',
      'repeat_before_have_all_ship': language.SHEIN_KEY_PC_26745 || '',
      'user_rejects': language.SHEIN_KEY_PC_26746 || '',
      'repeat_duplicate_product': language.SHEIN_KEY_PC_26747 || '',
      'repeat_as_historical_rejection': language.SHEIN_KEY_PC_26748 || '',
      'repeat_as_delivering': language.SHEIN_KEY_PC_26748 || ''
    }
    return {
      showCodRiskOrder: codRiskOrderAbt && is_cod_risk && !!cod_risk_type,
      showCodRiskOrderText: textMap[cod_risk_type] || ''
    }
  }
  // 打款账户说明文案
  /**
   * @param {String} receiver_country 收货国家
   * @param {Object} language 文案多语言
   * @param {String} paymentType 付款方式类型
   * @param {Boolean} hasAccount 是否已保存打款账户
  */
  getRefundAccountTips ({ receiverCountry = '', language = {}, paymentType = '', hasAccount = false }) {
    if (receiverCountry == 'Brazil') {
      if (!hasAccount || (hasAccount && ['PayPal', 'bankaccount'].includes(paymentType))) {
        return language.SHEIN_KEY_PC_27066
      } else {
        return language.SHEIN_KEY_PC_27067
      }
    } else {
      return language.SHEIN_KEY_PC_20248
    }
  }
  /**
   * @param {Object} orderGoods 商品行信息
   * @returns {Boolean} 当前订单参加【买X免Y】活动且商品行属于赠品
  */
  isFreeGiftItem(orderGoods = {}) {
    const { promotion_list = [], new_promotion_list = [], totalPrice } = orderGoods
    // 存在活动标识类型promotion_logo_type=2-买X免Y的商品,代表当前订单参加【买X免Y】活动
    const productInActivity = promotion_list?.some(item => item?.promotion_logo_type == 2)
    const isFree = Number(totalPrice?.amount) === 0
    // 识别付费会员免费礼物，打“FREE”标识: promotion_logo_type=8 付费会员满赠 且 type_id=4 全场满赠 且 is_present=1 赠品
    const isPrimeFreeGift = new_promotion_list?.some(item => item?.promotion_logo_type == 8 && item?.type_id == 4 && item?.is_present == 1)

    return productInActivity && isFree ? 1 : (isPrimeFreeGift ? 2 : 0)
  }
  /**
   * 是否显示repurchase按钮
   * 订单详情取preOrderGoodsList（因为有分包，否则取orderGoodsList）
   */
  isShowRepurchaseBtn(order = {}) {
    const orderGoodsList = getOrderGoodsList(order)
    // 所有商品都是定制商品的时候屏蔽repurchase按钮
    if (orderGoodsList.every(item => item.customization_flag == 1)) {
      return false
    }
    return true  
  }
  /**   
   * @param {Object} order 订单信息
   * @param {Array} goodsList 订单信息
   * @returns {Number} buyTp类型
  */
  getBuyTpInfo (order = {}, goodsList = []) {
    /**
    * 1:付费会员单独购订单
    * 2:付费会员随单购订单
    * 3:单独购超省卡订单
    * 4:随单购超省卡订单
    * 5:付费会员+超省卡+普通商品
    * 6:普通商品订单
    */
    let { order_type, xtra_order_scene } = order || {}
    
    // 是否存在超省卡商品
    let isExistXtraGoodsList = goodsList?.filter(item => item.is_xtra_goods)?.length > 0
    // 是否存在付费会员商品
    let isExistPrimeGoodsList = goodsList?.filter(item => item.is_prime_goods)?.length > 0

    if (isExistXtraGoodsList && isExistPrimeGoodsList) return 5
    if (isExistPrimeGoodsList) return order_type == 5 ? 1 : 2
    if (isExistXtraGoodsList) return xtra_order_scene == 1 ? 3 : 4
    return 6
  }

  isShowApplicationStatus (order = {}, returnStatusFrontAbt) {
    let { order_type = '', orderStatus = '' } = order || {}

    let orderStatusList = [7, 5, 11, 14, 17, 15]
    let isExistOrderType = order_type == 1
    let isExistOrderStatus = orderStatusList.includes(+orderStatus)
    return returnStatusFrontAbt && isExistOrderType && isExistOrderStatus
  }

  getShipInfo (order = {}) {
    return {
      fname: order.shipping_firstname,
      lname: order.shipping_lastname,
      fatherName: order.shipping_father_name,
      englishName: order.subsidiary?.english_name,
      middleName: order.subsidiary?.middle_name,
      countryId: order.shipping_country_id,
      country_name: order.shipping_country_name,
      state: order.shipping_province,
      city: order.shipping_city,
      district: order.shipping_district,
      street: order.shipping_street,
      address1: order.shipping_address_1,
      address2: order.shipping_address_2,
      postcode: order.shipping_postcode,
      taxNumber: order.tax_number,
      nationalId: order.nationalId,
      tel: order.shipping_telephone,
      new_tel: order.shipping_telephone,
      standbyTel: order.backupShippingTelephone,
      birthday: order.birthday,
      isDefault: 0,
      type: order.type,
      memberId: order.member_id,
      stateId: order.shipping_state_id,
      cityId: order.shipping_city_id,
      districtId: order.shipping_district_id,
      storeType: order.subsidiary?.store_type,
      storeId: order.subsidiary?.store_id,
      storeName: order.subsidiary.store_name,
      passportNumber: order.subsidiary?.passport_number,
      passportIssuePlace: order.subsidiary?.issue_place,
      passportIssueDate: order.subsidiary?.issue_date,
      lat: order.shipping_lat,
      lng: order.shipping_lng,
      calendar_type: order.calendar_type,
      id_expire_date: order.id_expire_date
    }
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  getRejectedReason (isRejectedReasonOn, order = {}) {
    if (!isRejectedReasonOn) return 0
    let { payment_method, reject_reason_id, order_package_info_list, orderGoodsList } = order || {}
    let allOrderGoodsList = orderGoodsList?.flat(Infinity) || []
    let codOrder = payment_method == 'cod'
    let rejectedReasonGoodsList = allOrderGoodsList.filter(item => [77].includes(+item.status))

    // 不满足前置条件不展示
    if (!(codOrder && rejectedReasonGoodsList.length)) return 0

    // 没有包裹不展示
    if (!order_package_info_list?.length) return 0

    // 满足条件分两种展示提示 包裹维度拒收原因两个值: null 或者 有值非-1
    let rejectReasonIdList = order_package_info_list?.filter(item => !!item.reject_reason_id) || []
    // 不是全部填写 且订单层级为-1 表示待填写
    let unFillable = reject_reason_id == -1 && rejectReasonIdList.length != order_package_info_list.length
    // 已填写 且订单层级不为-1 表示已填写
    let hadFillable = rejectReasonIdList.length == order_package_info_list.length || (reject_reason_id != -1 && !rejectReasonIdList.length)

    // 1: 待填写 2: 已填写 0: 不展示
    return unFillable ? 1 : (hadFillable ? 2 : 0)
  }

  // *获取订单详情或者订单列表下的商品列表
  getAllOrderGoodsList(order = {}){
    return  getOrderGoodsList(order) || []
  }
  
  // *陆运海运类型文案
  seaOrLandTypeText(order = {}, language = {}){
    const list = getOrderGoodsList(order) || []
    if(list.some(item =>  item?.large_sea_and_land == 1 )){
      return language?.SHEIN_KEY_PC_31765 || ''
    }
    if(list.some(item =>  item?.large_sea_and_land == 2 )){
      return language?.SHEIN_KEY_PC_31764 || ''
    }
    return ''
  }

  getAreaInfo({ itemData, receiverCountry }) {
    const countryCode = itemData?.countryCode || receiverCountry || ''
    const BRAZIL_SITES = ['br', 'mbr', 'iosshbr', 'andshbr']
    const MEXICO_SITES = ['mx', 'pwmx', 'iosshmx', 'andshmx']
    const { SiteUID } = window?.gbCommonInfo || {}
    let areaInfo = { area: '', code: 'AE' }
    
    if (countryCode === 'BR' || BRAZIL_SITES.includes(SiteUID) || countryCode === 'Brazil') {
      areaInfo = { area: 'brazil', code: 'BR' }
    } else if (countryCode === 'MX' || MEXICO_SITES.includes(SiteUID) || countryCode === 'Mexico') {
      areaInfo = { area: 'Mexico', code: 'MX' }
    }

    return areaInfo.area
  }

  /**
   * 满足以下两个条件，即视为需要提示用户维护”姓”+“名“的信息：
   * 1、Bankaccount打款渠道展示的字段为Checkout的字段
   * 2、用户已经保存的打款账户并且”paymentType“="bankaccount"，并且回参”姓“+”名“字段值任意为空
   * @property {Object} itemData 打款账户信息
   * @property {String} receiverCountry 收货国家
   */
  isNeedMaintainName({ itemData, receiverCountry = '', optionalPaymentMethod = [] }) {
    if (!optionalPaymentMethod.includes('bankaccount')) {
      return false
    }

    const {
      paymentType = '',
      accountId = '',
      cardholderFirstname = '',
      cardholderLastname = ''
    } = itemData || {}
    const area = this.getAreaInfo({ itemData, receiverCountry })
    // Checkout渠道
    const isCheckoutChannel = !['brazil', 'Mexico'].includes(area)
    // 需要维护”姓“+“名“的信息
    const needMaintainName = accountId && paymentType == 'bankaccount' && (!cardholderFirstname || !cardholderLastname)

    return isCheckoutChannel && needMaintainName
  }
}

export default new OrderLogic()
